<template>
  <b-form-group
    class="font-weight-semibold mb-0"
    :label="label">
    <b-input-group class="input-group-merge">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="innerValue"
        v-bind="$attrs"
        :placeholder="placeholder"
        v-on="$listeners"
      />
      <b-input-group-append
        v-if="innerValue && innerValue.length > 0"
        is-text>
        <b-icon
          class="cursor-pointer"
          icon="x-circle"
          scale="1"
          variant="danger"
          @click="clearInput()">
        </b-icon>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    clearInput () {
      this.innerValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
