import HttpRequest from './HttpRequest'

class UserGroupProvider extends HttpRequest {
  findAll (page = 1, limit = 10) {
    return this.get(`/user-group?page=${page}&limit=${limit}`)
  }

  findAllGroupCode () {
    return this.get('/user-group/group-code/all')
  }

  createGroup (payload) {
    return this.post('/user-group', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/user-group/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/user-group/${id}`)
  }

  paginate (page = 1, limit = 10, search, status) {
    return this.get(`/user-group?page=${page}&limit=${limit}&search=${search}&status=${status}`)
  }
}

export default UserGroupProvider
