import HttpRequest from './HttpRequest'

class UserTeamProvider extends HttpRequest {
  findAll () {
    return this.get('/user-team')
  }

  findAllTeamName () {
    return this.get('/user-team/team-name/all')
  }

  create (payload) {
    return this.post('/user-team', payload)
  }

  update (id, payload) {
    return this.patch(`/user-team/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/user-team/${id}`)
  }

  paginate (page = 1, limit = 10, searchTeam = '', searchGroup = '', status = '') {
    return this.get(`/user-team?page=${page}&limit=${limit}&searchTeam=${searchTeam}&searchGroup=${searchGroup}&status=${status}`)
  }
}

export default UserTeamProvider
