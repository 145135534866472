<template>
  <b-form-group
    class="font-weight-semibold mb-0"
    :label="label">
    <v-select
      v-model="innerValue"
      v-bind="$attrs"
      :options="options"
      :reduce="!returnValue ? val => val : val => val[returnValue]"
      :label="labelOption"
      :placeholder="placeholder"
      clearable />
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelOption: {
      type: String,
      default: 'label'
    },
    returnValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
